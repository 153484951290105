<template>
	<div class="chat-window">
		<!-- 上部分 -->
		<div class="top-box">
			<div class="chat-title">发邮件-选择收件人</div>
			<div class="list-content">
				<div class="top-button">
					<el-button round size="small" @click="$router.go(-1)">{{ '<<' }}</el-button>
				</div>
				<div class="commonly-used">
					<p>常用名单</p>
					<p>{{ total }}</p>
				</div>
				<div class="recipients-list">
					<div v-for="(p, i) in addressList" :key="i">
						<span style="font-size: 14px; color: #7b7d80; padding: 0 24px 12px 24px">{{ p.initial }}</span>
						<div v-for="(item, index) in p.result" :key="index" :class="
                current == item.id ? 'active-background' : 'recipients-info'
              " @click="lookUser(item, index, i)">
							<div class="user-left">
								<img :src="item.avatar" class="user-icon" />
								<p class="user_name">{{ item.nickName }}</p>
							</div>
							<img src="@/assets/images/ic_选中3@3x.png" alt="" class="select-image" v-if="item.isSelect" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部固定 -->
		<div class="bottom-btn-box">
			<div class="bottom-btn" @click="goBack"> {{ "<<" }} </div>
		</div>
	</div>
</template>

<script>
	import api from "@/api/api";
	import {
		json
	} from "body-parser";
	import eventBus from "../js/eventBus";
	export default {
		// 发邮件-选择收件人
		name: "recipientsDetail",
		data() {
			return {
				total: 0, //常用联系人总数
				current: null,
				addressList: [],
				saveAddressList: [],
				recipientsList: [{
						id: 1,
						name: "测试1",
						icon: require("@/assets/images/logo@3x.png")
					},
					{
						id: 2,
						name: "测试2",
						icon: require("@/assets/images/qrcode.png")
					},
					{
						id: 3,
						name: "测试3",
						icon: require("@/assets/images/logo@3x.png")
					},
					{
						id: 4,
						name: "测试4",
						icon: require("@/assets/images/logo@3x.png")
					},
					{
						id: 5,
						name: "测试5",
						icon: require("@/assets/images/logo@3x.png")
					},
				],
				model: {},
				selectVal: {}, //选中的数据
			};
		},
		created() {
			if (this.$route.query.model) {
				this.model = JSON.parse(this.$route.query.model);
			}
			this.getAddressBookList();

		},
		methods: {
			// 选择收件人 数据 子索引 父索引
			lookUser(val, index, i) {
				this.current = val.id;
				this.addressList.forEach((item, pi) => {
					if (pi == i) {
						item.result.forEach((p, ci) => {
							if (ci == index) {
								if (p.isSelect) {
									this.$set(p, "isSelect", false);
									this.selectVal = {}
								} else {
									this.$set(p, "isSelect", true);
									this.selectVal = p
								}
							} else {
								this.$set(p, "isSelect", false);
							}
						});
					} else {
						item.result.forEach((p, ci) => {
							this.$set(p, "isSelect", false);
						});
					}
				});
				this.goBack();
				// eventBus.$emit('selectUser',val);        //调用router回退页面
				// this.$router.go(-1);
				// this.$router.push({
				//   path: "sendEmails",
				//   query: { val: JSON.stringify(val), model: JSON.stringify(this.model) },
				// });
			},
			//返回上一页
			goBack() {
				if (JSON.stringify(this.model) == '{}') {
					this.$router.go(-1);
				} else {
					this.$router.go(-1);
					this.$router.push({
						path: "sendEmails",
						query: {
							val: JSON.stringify(this.selectVal),
							model: JSON.stringify(this.model)
						},
					});
				}
			},
			//获取用户详情
			getUserDetail(memberId) {
				api.userMsg.userDetail(memberId).then((res) => {
					this.$set(res.result, "id", res.result.id.toString());
					this.usrerMsgObj = res.result;
				});
			},
			//获取通讯录列表
			getAddressBookList() {
				//自己构造数组 构造有首字母缩写的的二级数组
				api.addressList
					.memberList()
					.then((res) => {
						eventBus.$emit("addressList", res.result);
						this.total = res.result.length;
						this.dealWithData(res);
					})
					.catch((err) => {
						this.$message({
							showClose: true,
							message: err.message,
							type: "error",
						});
					});
			},
			//处理数据 重构数组 增加字母索引 以及 bigNumber 的处理
			dealWithData(res) {
				if (res.result) {
					this.num = res.result.length;
				}
				let arr = [];
				res.result.forEach((item) => {
					this.$set(item, "memberId", item.memberId.toString());
					this.$set(item, "id", item.id.toString());
					this.$set(item, "circleId", item.id.toString());
					this.$set(item, "isSelect", false);
					let obj = {};
					obj.initial = item.initial;
					arr.push(obj);
				});
				//去重
				arr = [...new Set(arr)];
				//过滤
				arr.forEach((item) => {
					item.result = res.result.filter((p) => {
						return p.initial == item.initial;
					});
				});
				//去重
				//每次进来赋值为空
				this.addressList = [];
				this.saveAddressList = [];
				//数组如果一个个对象没有办法直接去重 需要把对象每个进行字符串化  变成['{xx:xx}','{xx:xx}']
				//构造一个去重数组
				let strArr = [];
				arr.forEach((item) => {
					strArr.push(JSON.stringify(item));
				});
				strArr = [...new Set(strArr)];
				//赋值
				strArr.forEach((item) => {
					this.addressList.push(JSON.parse(item));
					this.saveAddressList.push(JSON.parse(item));
				});
				console.log(this.addressList);
				if (JSON.stringify(this.model) != '{}') {
					this.addressList.forEach(item => {
						item.result.forEach(p => {
							console.log(p.memberId, this.model.receiveMemberId)
							if (p.memberId == this.model.receiveMemberId) {
								this.$set(p, 'isSelect', true)
								this.selectVal = p
							}
						})
					})
				}

				//获取第一个的展示页面 详情
				// this.getUserDetail(this.addressList[0].result[0].memberId)
			},
		},
	};
</script>

<style scoped>
	.chat-window {
		min-width: 506px;
		flex: 1;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		background: #ffffff;
		border-left: 1px solid #dcdfe6;
		position: relative;
		justify-content: space-between;
		overflow:auto;
	}

	.chat-window .chat-title {
		display: flex;
		align-items: center;
		height: 60px;
		font-size: 18px;
		color: #313233;
		letter-spacing: 2px;
		padding: 0 20px;
		background: #ffffff;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
	}

	.top-button {
		height: 48px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 10px;
		border-bottom: 1px solid #dcdfe6;
	}

	.top-button button {
		width: 56px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.list-content {
		flex: 1;
		padding: 0 0 12px 0;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.list-content .commonly-used {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 14px;
		color: #313233;
		margin: 12px 0;
		padding: 0 12px;
	}

	.commonly-used p:nth-child(2) {
		color: #abaeb3;
		margin-left: 8px;
	}

	.recipients-list {
		display: flex;
		flex-direction: column;
		padding: 12px 0;
		box-sizing: border-box;
		width: 100%;
	}

	.recipients-list .recipients-info {
		height: 56px;
		display: flex;
		align-items: center;
		padding: 0 24px;
		justify-content: space-between;
		width: 100%;
	}

	.recipients-info:hover {
		background: #f5f7fa;
	}

	.active-background {
		height: 56px;
		display: flex;
		align-items: center;
		background: #f5f7fa;
		padding: 0 24px;
		justify-content: space-between;
		/* width: 100%; */
	}

	.user-icon {
		width: 36px;
		height: 36px;
		border-radius: 50%;
		margin-right: 8px;
	}

	.user-left {
		display: flex;
		align-items: center;
	}

	.select-image {
		width: 14px;
		height: 14px;
		flex-shrink: 0;
	}

	.bottom-btn-box {
		height: 70px;
		padding: 10px;
		display: flex;
		align-items: center;
		border-top: 1px solid #dcdfe6;
	}

	.bottom-btn {
		width: 48px;
		height: 28px;
		background: #ffffff;
		border-radius: 14px;
		border: 1px solid #dcdfe6;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #313233;
		cursor: pointer;
	}
</style>